import React from 'react';
import * as styles from './SiteInfo.module.scss';
import { Card, Container } from 'react-bootstrap';
import CustomButton from '../CustomButton/CustomButton';

const SiteInfo = () => {
  return (
    <section className={styles.site_info}>
      <Container>
        <div className={styles.wrapper}>
          <div className={styles.first_column}>
            <h3>Dlaczego Nowoczesna Witryna?</h3>
            <h4>Oferuję profesjonalne usługi z zakresu tworzenia stron internetowych.</h4>
            <p>Moje strony są responsywne, funkcjonalne, łatwe w zarządzaniu i zoptymalizowane pod kątem SEO.</p>
            <CustomButton variant="dark" text="skontaktuj się" link="#kontakt" />
          </div>
          <div className={styles.second_column}>
            <Card className={`mb-2 ${styles.first_card}`}>
              <Card.Body>
                <Card.Title>Nie jestem kolejną agencją</Card.Title>
                <Card.Text>
                  Jako freelancer, gwarantuję indywidualne podejście, elastyczność i pełne zaangażowanie w każde
                  zlecenie. Pozwala mi to oferować usługi najwyższej jakości, szybką realizację i atrakcyjne ceny.
                </Card.Text>
              </Card.Body>
            </Card>
            <Card className={styles.second_card}>
              <Card.Body>
                <Card.Title>Nie tylko WordPress!!</Card.Title>
                <Card.Text>
                  W przeciwieństwie do większości freelancerów, nie ograniczam się jedynie do WordPressa. Jestem także
                  programistą, co pozwala mi tworzyć strony na różnych platformach i dostosowywać je do Twoich
                  unikalnych potrzeb.
                  <span>
                    <strong>
                      <a href="#omnie"> Zobacz moje umiejętności i doświadczenie.</a>
                    </strong>
                  </span>
                </Card.Text>
              </Card.Body>
            </Card>
          </div>
        </div>

        <div className={styles.banner}>
          <h3 data-sal="slide-up" data-sal-delay="300" data-sal-easing="ease" data-sal-duration="1000">
            Współpracuję z firmami każdego <strong>rodzaju i wielkości</strong>. Bez względu na to, czy prowadzisz mały
            start-up, czy dużą korporację, jestem przekonany, że mogę zaoferować Ci odpowiednie rozwiązania.
          </h3>
        </div>
      </Container>
    </section>
  );
};

export default SiteInfo;
